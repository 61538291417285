import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@env/environment';
import { Communication } from '@models/loyalty-libs';
import { BehaviorSubject, Observable, distinctUntilChanged, map, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommunicationsWebservice {
  readonly #http = inject(HttpClient);
  readonly #partnerId$ = new BehaviorSubject<string | null>(null);

  readonly #communicationApi$ = this.#partnerId$.pipe(
    distinctUntilChanged(),
    map((partnerId) => `${environment.api.obendy}/v2/partner/${partnerId}`)
  );

  readonly #baseUrl = `${environment.api.obendy}/v2/communications`;

  public readonly getCommunications = (): Observable<Communication[]> =>
    this.#communicationApi$.pipe(
      map((api) => `${api}/communications`),
      switchMap((endpoint) => this.#http.get<Communication[]>(endpoint))
    );

  public deleteCommunication(communicationId: string): Observable<undefined> {
    return this.#http.delete<undefined>(`${this.#baseUrl}/${communicationId}`);
  }

  public setPartnerId(partnerId: string): void {
    this.#partnerId$.next(partnerId);
  }

  public readonly createCommunication = (communication: Omit<Communication, 'id'>): Observable<Communication> => {
    return this.#http.post<Communication>(this.#baseUrl, { ...communication });
  };

  public readonly putCommunication = (
    communication: Omit<Communication, 'id'>,
    communicationId: string
  ): Observable<Communication> => {
    return this.#http.put<Communication>(`${this.#baseUrl}/${communicationId}`, { ...communication });
  };

  public readonly removeHighlightOnCommunication = (
    communication: Omit<Communication, 'id'>,
    communicationId: string
  ): Observable<Communication> => {
    return this.#http.put<Communication>(`${this.#baseUrl}/${communicationId}`, {
      ...communication,
      content: { ...communication.content, highlighted: false },
    });
  };

  public readonly getCommunication = (communicationId: string): Observable<Communication> => {
    return this.#http.get<Communication>(`${this.#baseUrl}/${communicationId}`);
  };
}
